export const environment = {
	production: false,
	USER_API_URL:"https://2h2ozsrc9i.execute-api.ap-south-1.amazonaws.com/dev/",
	API_KEY:"qsznbQdRir4qychwA7wGs4oN5wEmlUs77mRRIi0U",
	File_Storage_URL: 'https://backend-content-storage.s3.ap-south-1.amazonaws.com/HC_Staging/',
	health_passport_url:"https://backend-content-storage.s3.ap-south-1.amazonaws.com/HP/",
	firebaseConfig : {
		apiKey: "AIzaSyB8nPDxTJGmW6YMzwvGdtUcFZwg25Xeba0",
		authDomain: "videocall-staging.firebaseapp.com",
		databaseURL: "https://videocall-staging.firebaseio.com",
		projectId: "videocall-staging",
		storageBucket: "videocall-staging.appspot.com",
		messagingSenderId: "251343964324",
		appId: "1:251343964324:web:339ea2743c66a8f3f0be31"
	},
	firebaseLogin:{
		username:"dummyfirebase@test.com",
		password:"FirePass@88"
	},
	firebaseJSFile:"firebase-messaging-sw-staging.js",
	version:'3.0.2',
	email_id:'helpdesk@emails.3cubehealth.com',
	agoraAppID:'1e6b26f2e5664603bd1fee038bae4dca',
	symptomModuleId:178,
	audioVideoCallReasonId:"50"
};
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mail-content',
  templateUrl: './mail-content.component.html',
  styleUrl: './mail-content.component.css'
})
export class MailContentComponent implements OnInit,AfterViewInit {
	@Input() mailContent: any;
	@ViewChild('iframe') iframe!: ElementRef;
	style:any = {
		"width":"100%",
		"height":"100vh"
	};
	constructor() { }
	ngAfterViewInit(): void {
		let doc =  this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
		if(doc){
			doc.open();
			doc.write(this.mailContent);
			doc.close();
		}
	}
	
	ngOnInit(): void {
	}

	resizeIframe(iframe:any){
		try {
			var height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
		} catch (error) {
			var height = "100vh";
		}
		this.style.height = height;
	}
}

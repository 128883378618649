import { Component, OnInit, AfterViewInit, Input, Output, OnDestroy, ViewChild, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-infinite-scroll',
  template: '<ng-content></ng-content><div #anchorscroll></div>',
  styleUrl: './infinite-scroll.component.css'
})
export class InfiniteScrollComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() options = {};
	@Output() scrolled = new EventEmitter();
	@ViewChild('anchorscroll')
  anchor!: ElementRef<HTMLElement>;

	private observer!: IntersectionObserver;
	constructor(private host: ElementRef) { }

	ngOnInit(): void {
		
	}

	ngAfterViewInit(){
		const options = {
			root: this.isHostScrollable() ? this.host.nativeElement : null,
			/*root: null,*/
			...this.options
		};
		this.observer = new IntersectionObserver(([entry]) => {
			entry.isIntersecting && this.scrolled.emit();
		}, options);

		this.observer.observe(this.anchor.nativeElement);
	}

	ngOnDestroy() {
		this.observer.disconnect();
	}

	get element() {
		return this.host.nativeElement;
	}

	private isHostScrollable() {
		const style = window.getComputedStyle(this.element);
		return style.getPropertyValue('overflow') === 'auto' ||
		style.getPropertyValue('overflow-y') === 'scroll';
	}
}
